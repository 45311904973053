import React from 'react';
import PropTypes from 'prop-types';
import Nav from './nav';
import SEO from './seo';
import Footer from './footer';
import SingUp from './sign-up';

const BarionNoScript = () => (
  <noscript>
    <img
      height="1"
      width="1"
      style={{ display: 'none' }}
      alt="Barion Pixel"
      src="https://pixel.barion.com/a.gif?ba_pixel_id='BP-WqvscKtbbx-0F'&ev=contentView&noscript=1"
    />
  </noscript>
);

const Layout = ({ children, seo }) => {
  const { title, description, ogImage, url } = seo;

  return (
    <>
      <BarionNoScript />
      <SEO
        title={title}
        description={description}
        ogImage={ogImage}
        url={url}
      />
      <Nav />
      <main>{children}</main>
      <SingUp />
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    ogImage: PropTypes.string,
    url: PropTypes.string
  }).isRequired
};

export default Layout;
