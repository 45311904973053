import clsx from 'clsx';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const HamburgerMenu = ({ show }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      className="mx-auto"
    >
      {show ? (
        <>
          <path d="M18 6L6 18" />
          <path d="M6 6L18 18" />
        </>
      ) : (
        <>
          <path d="M3 12L21 12" />
          <path d="M3 6L21 6" />
          <path d="M3 18L21 18" />
        </>
      )}
    </svg>
  );
};

const Nav = () => {
  const [show, setShow] = useState(false);

  const headerClass = clsx('fixed w-full z-20 bg-white top-0 shadow-lg', {
    'h-full': show
  });

  const navClass = clsx(
    'w-full h-full flex flex-col items-center justify-center lg:flex lg:w-2/3 lg:flex-row lg:justify-end',
    { hidden: !show }
  );

  return (
    <header className={headerClass}>
      <div className="flex flex-col h-full lg:flex-col-reverse pt-1">
        <div className="flex-grow flex flex-col container lg:flex-row lg:items-center h-full">
          <div className="w-full lg:w-1/3">
            <div className="flex flex-row">
              <Link
                className="w-3/4 m-5 text-green-700 font-bold text-xl uppercase cursor-pointer hover:text-orange-500"
                to="/"
              >
                <h3>Fortély-sátor</h3>
              </Link>
              <button
                type="button"
                onClick={() => setShow(!show)}
                className="w-1/4 outline-none lg:hidden"
              >
                <HamburgerMenu show={show} />
              </button>
            </div>
          </div>
          <nav className={navClass}>
            <Link
              to="/"
              activeClassName="text-green-700 border-green-700 border-b-2"
              className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold lg:inline-block hover:text-green-700 cursor-pointer mr-5"
            >
              Főoldal
            </Link>
            <Link
              to="/modszerunk"
              activeClassName="text-green-700 border-green-700 border-b-2"
              className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold lg:inline-block hover:text-green-700 cursor-pointer mr-5"
            >
              Módszerünk
            </Link>
            <Link
              to="/rolunk"
              activeClassName="text-green-700 border-green-700 border-b-2"
              className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold lg:inline-block hover:text-green-700 cursor-pointer mr-5"
            >
              Csapatunk
            </Link>

            <Link
              to="/vallalatoknak"
              activeClassName="text-green-700 border-green-700 border-b-2"
              className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold lg:inline-block hover:text-green-700 cursor-pointer mr-5"
            >
              Vállalatoknak
            </Link>
            <Link
              to="/celcsoportnak"
              activeClassName="text-green-700 border-green-700 border-b-2"
              className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold lg:inline-block hover:text-green-700 cursor-pointer mr-5"
            >
              Célcsoportnak
            </Link>
            <Link
              to="/blog"
              activeClassName="text-green-700 border-green-700 border-b-2"
              className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold lg:inline-block hover:text-green-700 cursor-pointer mr-5"
            >
              Aktuális
            </Link>
            <Link
              to="/kapcsolat"
              activeClassName="text-green-700 border-green-700 border-b-2"
              className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold lg:inline-block hover:text-green-700 cursor-pointer mr-5"
            >
              Kapcsolat
            </Link>
            <Link
              to="/tamogatas"
              activeClassName="text-green-700 border-green-700 border-b-2"
              className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold lg:inline-block uppercase text-orange-500 hover:text-green-700 cursor-pointer mr-5"
            >
              Támogass minket!
            </Link>
            <Link
              to="/egy-szazalek"
              activeClassName="text-green-700 border-green-700 border-b-2"
              className="text-2xl md:text-base block mt-4 lg:mt-0 font-bold lg:inline-block uppercase text-orange-500 hover:text-green-700 cursor-pointer mr-5"
            >
              1%
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

HamburgerMenu.propTypes = {
  show: PropTypes.bool.isRequired
};
export default Nav;
