import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

export const baseClasses = [
  'text-center tracking-wider font-bold uppercase', // text
  'py-4 px-10 border-2 border-primary rounded-full inline-block m-4 min-w-64 lg:ml-0 mx-auto', // shape
  'hover:shadow-xl transition duration-200 ease-in-out transform hover:-translate-y-1' // effect
];

export const variants = {
  primary: 'bg-primary text-white hover:bg-primary',
  secondary: 'bg-white text-primary hover:text-orange-600',
  outlined: 'bg-transparent text-primary hover:text-orange:600'
};

const Button = ({ children, to, className, variant = 'primary' }) => {
  const variantClasses = variants[variant];

  return (
    <Link
      className={clsx(baseClasses, variantClasses, className)}
      to={to}
      aria-label="Link"
    >
      {children}
    </Link>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};

export default Button;
