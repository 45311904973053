import React, { useState } from 'react';
import clsx from 'clsx';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { baseClasses, variants } from './button';

const SingUp = () => {
  const [send, setSend] = useState('');

  const initialValues = {
    name: '',
    email: '',
    gdpr: false
  };

  const onSubmit = async ({ name, email }) => {
    try {
      axios.post('/api/subscribe', { name, email });
      setSend('success');
    } catch (error) {
      setSend('fail');
    }
  };
  const CheckOut = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Kötelező kitölteni!'),
    email: Yup.string()
      .min(5, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Kötelező kitölteni!'),
    gdpr: Yup.string().required('Kötelező kitölteni!')
  });

  return (
    <div className="container md:my-20 lg:px-20">
      <div className="relative mx-auto bg-bgfirst max-w-xl md:flex md:flex-row md:rounded-xl">
        <div className="md:w-1/2 pt-8 pb-4">
          <h2 className="text-center max-w-md uppercase">
            Iratkozz fel A hírlevelünkre!
          </h2>
          <div className="absolute left-0 top-0 mt-48 mr-10 -ml-3 md:w-1/2">
            <img
              alt="Adatvédelem"
              src="/kite.png"
              className="ml-3 mb-5"
              width="540"
            />
          </div>
        </div>
        <div className="md:w-1/2 pt-8 pb-4">
          {!send ? (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={CheckOut}
            >
              <Form className="w-full mx-auto px-2 lg:px-16 pt-10 pb-10  rounded-lg md:p-5">
                <div className="flex flex-col my-5">
                  <div className="px-3 mb-6">
                    <p className="text-sm font-semibold mb-2">Keresztnév</p>
                    <Field
                      className="border border-gray-500 rounded-md p-2 w-full focus:outline-none focus:border-2 focus:border-primary focus:border-transparent"
                      placeholder="Jakab"
                      id="name"
                      type="text"
                      name="name"
                      required
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className=" text-red-700 p-1"
                    />
                  </div>
                  <div className="px-3 mb-6">
                    <p className="text-sm font-semibold mb-2">Email cím</p>
                    <Field
                      className="border border-gray-500 rounded-md p-2 w-full focus:outline-none focus:border-2 focus:border-primary focus:border-transparent"
                      placeholder="jakab@gmail.com"
                      id="email"
                      type="text"
                      name="email"
                      required
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className=" text-red-700 p-1"
                    />
                  </div>
                  <div className="px-3 flex flex-row">
                    <Field
                      name="gdpr"
                      type="checkbox"
                      className="mt-1 mr-2"
                      required
                    />
                    <ErrorMessage
                      name="gdpr"
                      component="div"
                      className=" text-red-700 p-1"
                    />
                    <p className="text-sm font-semibold mb-2 w-full">
                      Elolvastam és elfogadom az{' '}
                      <a
                        href="/FsA_Adatvédelmi_nyilatkozat.pdf"
                        className="text-primary"
                      >
                        Adatvédelmi tájékoztatót
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="text-center md:text-left md:pl-5">
                  <button
                    type="submit"
                    className={clsx(baseClasses, variants.primary)}
                  >
                    Feliratkozás
                  </button>
                </div>
              </Form>
            </Formik>
          ) : (
            <div className="m-20 bg-white rounded-lg p-5">
              {send === 'success' ? (
                <div>
                  <img
                    alt="Sikeres küldés"
                    src="/icons/done.svg"
                    width={100}
                    className="mx-auto mt-2"
                  />
                  <h4 className="text-center mt-5">Sikeres feliratkozás!</h4>
                  <div className="text-center ">
                    <button
                      type="button"
                      className=" mt-5 text-primary hover:underline hover:text-secondary"
                      onClick={() => setSend('')}
                    >
                      Vissza
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <img
                    alt="Sikeres küldés"
                    src="/icons/services.svg"
                    width={100}
                    className="mx-auto mt-2"
                  />
                  <h4 className="text-center mt-5">Sikertele feliratkozás:(</h4>
                  <div className="text-center ">
                    <button
                      type="button"
                      className=" mt-5 text-primary hover:underline hover:text-secondary"
                      onClick={() => setSend('')}
                    >
                      Próbáld meg újra
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingUp;
