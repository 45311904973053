import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Button from './button';

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          inspiro: file(relativePath: { regex: "/footer/inspiro.png/" }) {
            childImageSharp {
              fixed(width: 259, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={() => (
        <footer className="bg-bgsecond relative">
          <div className="absolute bottom-0 left-0 align-middle lg:text-left bg-secondary lg:rounded-tr-large text-white p-10 lg:p-20 w-full lg:w-auto text-center">
            <h3 className=" uppercase">Fortély-sátor</h3>
            <p> A siker házhoz jön.</p>
            <div className="justify-center flex mt-4">
              <a
                href="https://www.facebook.com/fortelysator/"
                target="_blank"
                rel="noreferrer"
                className="mr-4"
              >
                <img alt="Facebook" src="/icons/face.svg" width="30" />
              </a>
              <a
                href="https://www.instagram.com/fortelysator/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Instagram"
                  className="face mr-4"
                  src="/icons/insta.svg"
                  width="30"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/fort%C3%A9ly-s%C3%A1tor-alap%C3%ADtv%C3%A1ny/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Instagram"
                  className="face mr-4"
                  src="/icons/linked.svg"
                  width="30"
                />
              </a>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="flex flex-wrap text-center pb-64 lg:pb-20 justify-center">
              <div className="w-full lg:w-2/6 xl:w-1/4 xl:ml-10" />
              <div className="lg:w-2/6  md:flex md:flex-grow justify-center">
                <div className="md:w-1/2 p-4 flex flex-col md:text-left lg:mt-16 font-bold justify-items-center">
                  {/* <a
                    href="/AlapitoOkirat.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-4 hover:text-orange-500"
                  >
                    Alapító okirat
                  </a> */}
                  <a
                    href="/FsA_Adatvédelmi_nyilatkozat.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-4 hover:text-orange-500"
                  >
                    Adatvédelmi tájékoztató
                  </a>
                  <a
                    href="/Adomanyozas.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-4 hover:text-orange-500"
                  >
                    Adományozási feltételek
                  </a>
                  {/* <Button
                    to="https://fortelysator.us1.list-manage.com/subscribe?u=3abf23d727b1447f8041a2a7f&id=c29c6130f8"
                    variant="secondary"
                    className="mt-5"
                  >
                    Hírlevél
                  </Button> */}
                </div>
                <div className="p-4 flex flex-col md:text-left lg:mt-16 font-bold justify-items-center">
                  <Button to="/tamogatas">Támogass minket!</Button>
                  <p className="pt-4 italic md:max-w-md lg:max-w-lg mx-auto">
                    Sikerélményt viszünk a fiatalok életébe szociális helyzettől
                    függetlenül.
                  </p>
                </div>
                <div className="md:w-2/3 p-4 flex flex-col md:text-left lg:mt-16 font-bold justify-items-center">
                  <img alt="Barion" src="/logo.png" className="pt-4" />
                  <p className="pt-4 text-xs max-w-md mx-auto mb-4">
                    A kényelmes és biztonságos online fizetést a Barion Payment
                    Zrt. biztosítja. Magyar Nemzeti Bank engedély száma:
                    H-EN-I-1064/2013 Bankkártya adatai szervezetünkhöz nem
                    jutnak el.
                  </p>
                </div>
              </div>
            </div>
            <a
              href="https://inspirostudio.hu/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Inspio Studio"
                src="/spiro_dark.png"
                width="250"
                className="mx-auto pb-5 "
              />
            </a>
          </div>
        </footer>
      )}
    />
  );
};

export default Footer;
