import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        siteUrl
        description
        keywords
      }
    }
  }
`;

const SEO = ({ title, description, keywords, ogImage, url }) => {
  const { site } = useStaticQuery(query);
  console.log(url);

  return (
    <Helmet
      htmlAttributes={{
        lang: 'hu'
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description || site.siteMetadata.description
        },
        {
          name: `keywords`,
          content: keywords || site.siteMetadata.keywords
        },
        {
          property: `og:title`,
          content: title || site.siteMetadata.title
        },
        {
          property: `og:description`,
          content: description || site.siteMetadata.description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl + url || site.siteMetadata.siteUrl
        },
        {
          property: `og:image`,
          content: ogImage || `${site.siteMetadata.siteUrl}/rsz_hero_bg.jpg` // 1200x630 for facebook
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: 'author'
        },
        {
          name: `twitter:title`,
          content: title || site.siteMetadata.title
        },
        {
          name: `twitter:description`,
          content: description || site.siteMetadata.description
        }
      ]}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  ogImage: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  url: PropTypes.string
};

export default SEO;
